import * as R from 'ramda';
import styled from 'styled-components';
import {
  space,
  width,
  color,
  zIndex,
  height,
  border,
  fontSize,
  minWidth,
  minHeight,
  maxHeight,
} from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

export const EmptyList = styled.div`
  font-size: 36px;
  padding: ${({ p }: Object) => p || '50px 0'};
  color: ${() => G.getTheme('colors.light.lightGrey')};
  text-align: ${({ textAlign }: Object) => textAlign || 'center'};
  background-color: ${() => G.getTheme('colors.light.mainLight')};
`;

export const TableWrapper = styled.div`
  ${width}
  ${space}
  ${color}
  ${border}
  ${minHeight}
  ${maxHeight}

  overflow: ${({ overflow }: Object) => overflow || 'auto'};
  border: ${({ withoutBorder }: Object) => (
    R.not(withoutBorder) && `1px solid ${G.getTheme('tables.rows.borderColor')}`
  )};
`;

TableWrapper.defaultProps = {
  maxHeight: 500,
};

export const TableInner = styled.div`
  ${width}
  ${minWidth}
`;

TableInner.defaultProps = {
  minWidth: 'fit-content',
};

export const getTitleRowBgColor = (props: Object) => G.ifElse(
  G.isTrue(props.colored),
  G.getTheme(G.ifElse(props.mainColor, 'tables.rows.additionalBgColor', 'tables.rows.bgColorActive')),
  R.or(props.bgColor, G.getTheme('tables.header.bgColor')),
);

export const TitleRow = styled.div`
  ${space}
  ${color}
  ${height}
  ${fontSize}

  display: flex;
  min-width: 100%;
  width: fit-content;

  background-color: ${(props: Object) => getTitleRowBgColor(props)};
  border-bottom: ${() => `1px solid ${() => G.getTheme('tables.rows.borderColor')}`};
`;

TitleRow.defaultProps = {
  minHeight: 40,
};

export const TitleCell = styled.div`
  ${space}
  ${width}
  ${zIndex}
  ${fontSize}
  ${minWidth}

  height: 100%;
`;

TitleCell.defaultProps = {
  minWidth: 'unset',
};

export const StickedCell = styled.div`
  ${space}
  ${width}
  ${zIndex}

  position: sticky;

  top: ${({ top }: Object) => top || 'unset'};
  left: ${({ left }: Object) => left || 'unset'};
  right: ${({ right }: Object) => right || 'unset'};
  bottom: ${({ bottom }: Object) => bottom || 'unset'};
  min-width: ${({ minWidth }: Object) => minWidth || 'unset'};
  background-color: ${(props: Object) => props.bgColor || 'unset'};
`;

StickedCell.defaultProps = {
  zIndex: 1,
};

const getTableRowBgColor = (props: Object, hover: boolean) => (
  R.or(
    props.bgColor,
    G.ifElse(
      props.active,
      G.getTheme(G.ifElse(
        props.mainColors,
        R.or(props.activeRowColor, 'tables.rows.bgColorActive'),
        'tables.rows.additionalBgColor',
      )),
      G.ifElse(
        hover,
        G.getTheme('tables.rows.hoverBgColor'),
        G.getTheme(G.ifElse(
          R.prop('highlighted', props),
          'tables.rows.highlightedBgColor',
          'tables.rows.bgColor',
        )),
      ),
    ),
  )
);

const getTableRowBorderColor = (props: Object) => G.ifElse(
  props.active,
  G.getTheme(G.ifElse(
    G.isNotNil(props.activeBorderColor),
    props.activeBorderColor,
    G.ifElse(props.mainColors, 'tables.rows.borderColorActive', 'tables.rows.additionalBorderColor'),
  )),
  G.getTheme(G.ifElse(
    G.isNotNil(props.borderColor),
    props.borderColor,
    'tables.rows.borderColor',
  )),
);

export const TableCell = styled(Box)`
  ${zIndex}

  height: 100%;
`;

TableCell.defaultProps = {
  minWidth: 'unset',
};

export const CellWithBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props: Object) => getTableRowBgColor(props)};
`;

export const TableRow = styled(Flex)`
  min-width: 100%;
  width: fit-content;

  background-color: ${(props: Object) => getTableRowBgColor(props)};
  text-decoration: ${({ textDecoration }: Object) => textDecoration};
  border-bottom: ${(props: Object) => `1px solid ${getTableRowBorderColor(props)}`};
  border-top: ${(props: Object) => G.isNotNil(props.borderTop) && `1px solid ${getTableRowBorderColor(props)}`};

  &:hover {
    background-color: ${(props: Object) => getTableRowBgColor(props, true)};
    & .cell-with-background {
      background-color: ${(props: Object) => getTableRowBgColor(props, true)};
    }
  }
`;

TableRow.defaultProps = {
  height: 40,
};

export const TitleCellWithBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props: Object) => getTitleRowBgColor(props)};
`;

export const WithBoxShadow = styled.div`
  height: 100%;
  display: flex;
  box-shadow: ${({ boxShadow }: Object) => (
    boxShadow || `inset -2px 0px 0px ${G.getTheme('colors.boxShadowLightGrey')}`
  )};
`;

const getCheckboxBorderColor = (props: Object) => {
  const { disabled, checkBoxCellBorderColor } = props;

  if (G.isTrue(disabled)) return G.getTheme('colors.light.lightGrey');

  if (G.isString(checkBoxCellBorderColor)) return checkBoxCellBorderColor;

  return G.getTheme('forms.checkboxes.borderColor');
};

export const Checkbox = styled.input`
  ${space}

  z-index: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &:before {
    left: 0;
    top: 50%;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    transform: translate(0, -50%);
    background-color: ${() => G.getTheme('forms.checkboxes.bgColor')};
    border: 1px solid ${(props: Object) => getCheckboxBorderColor(props)};
  }

  &:checked:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    position: absolute;
    background-color: ${() => G.getTheme('forms.checkboxes.bgColorChecked')};
    border: 1px solid ${({ disabled, mainColors }: Object) => G.getTheme(G.ifElse(
      disabled,
      'colors.light.lightGrey',
      G.ifElse(
        G.isTrue(mainColors),
        'forms.checkboxes.borderColorChecked',
        'forms.checkboxes.additionalBorderColor',
      ),
    ))};
  }

  &:checked:after {
    border: 1px solid ${(props: Object) => G.getTheme(G.ifElse(
      props.mainColors,
      'forms.checkboxes.textColorChecked',
      'forms.checkboxes.additionalTextColor',
    ))};
    left: 0;
    top: 50%;
    width: 3px;
    height: 8px;
    content: '';
    position: absolute;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(0, -90%);
  }
`;

export const FlexContainer = styled.div`
  ${space}
  ${height}

  display: flex;
  align-items: ${({ align }: Object) => align};
  justify-content: ${({ justify }: Object) => justify};
  flex-direction: ${({ direction }: Object) => direction};
`;

export const ActionIcon = styled(Box)`
  cursor: pointer;

  ${({ disabled }: Object) => (
    R.and(
      disabled,
      `
        opacity: 0.5;
        cursor: not-allowed;
      `,
    )
  )}
`;
