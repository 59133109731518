import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box, Span, SimpleWrapper } from '../../ui';
//////////////////////////////////////////////////

const TrackingAnalyticItem = (props: Object) => {
  const { title, value } = props;

  return (
    <Box fontSize={12} fontWeight='bold'>
      {title}: <Span ml='5px'>{value}</Span>
    </Box>);
};

const TrackingAnalytic = ({ events, pings }: Object) => {
  const getEventProp = (position: string, prop: string) => R.compose(
    R.prop(prop),
    R[position],
    R.values,
  )(events);

  const firstEventDate = getEventProp('head', GC.FIELD_LOAD_EVENT_EARLY_DATE);
  const lastEventDate = getEventProp('last', GC.FIELD_LOAD_EVENT_EARLY_DATE);

  const firstEvenLocation = getEventProp('head', 'location');
  const lastEventLocation = getEventProp('last', 'location');

  const distance = G.getDistanceBetweenTwoPoints(firstEvenLocation, lastEventLocation);
  const dateRange = G.getDateRange(firstEventDate, lastEventDate, GC.DEFAULT_DATE_FORMAT);

  const analyticData = [
    {
      value: G.convertInstanceToDefaultDateFormat(firstEventDate),
      title: G.getWindowLocale('titles:first-event', 'First Event'),
    },
    {
      value: G.convertInstanceToDefaultDateFormat(lastEventDate),
      title: G.getWindowLocale('titles:last-event', 'Last Event'),
    },
    {
      title: G.getWindowLocale('titles:duration', 'Duration'),
      value: G.getIntervalToDuration(firstEventDate, lastEventDate),
    },
    // TODO: NEED TO IMPLEMENT IF NEEDED
    // {
    //   title: G.getWindowLocale('titles:distance', 'Distance'),
    //   value: `${G.mathRoundNumber(distance)} KM`,
    // },
    {
      value: pings,
      title: G.getWindowLocale('titles:pings', 'Pings'),
    },
  ];

  return (
    <SimpleWrapper mb={15} width='100%' jc='space-between'>
      {analyticData.map((data: Object, index: number) => <TrackingAnalyticItem key={index} {...data} />)}
    </SimpleWrapper>
  );
};

export default TrackingAnalytic;
