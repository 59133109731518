import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
// helpers
import * as G from '../../helpers';
// ui
import {
  Label,
  Container,
  FakeLabel,
  InputField,
  FakeLabelContainer,
  InputFieldContainer,
  FakeOutlineContainer,
  FakeOutlineContainerWithoutBorder,
} from './ui';
//////////////////////////////////////////////////

const Input = (props: Object) => {
  const {
    value,
    label,
    inputRef,
    onChange,
    isFocused,
    onKeyPress,
    handleBlur,
    placeholder,
    handleFocus,
    withoutBorder,
    inputReadOnly,
    type = 'string',
    readOnly = false,
    inputStyles = {},
    onClick = () => {},
  } = props;

  const { width, height, maxWidth } = inputStyles;

  const isFocusedOrIsValue = R.or(isFocused, G.isNotNilAndNotEmpty(value));

  const OutlineContainer = G.isTrue(withoutBorder) ? FakeOutlineContainerWithoutBorder : FakeOutlineContainer;

  return (
    <Container width={width} height={height} maxWidth={maxWidth}>
      <Label shouldUpdateStyles={isFocusedOrIsValue}>
        { label }
      </Label>
      <InputFieldContainer>
        <InputField
          type={type}
          value={value}
          title={label}
          ref={inputRef}
          onClick={onClick}
          onChange={onChange}
          onBlur={handleBlur}
          isFocused={isFocused}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          readOnly={R.or(readOnly, inputReadOnly)}
          placeholder={G.ifElse(R.and(isFocused, G.isNilOrEmpty(value)), placeholder)}
        />
        <OutlineContainer shouldUpdateStyles={isFocusedOrIsValue}>
          <FakeLabelContainer shouldUpdateStyles={isFocusedOrIsValue}>
            <FakeLabel title={label}>
              { label }
            </FakeLabel>
          </FakeLabelContainer>
        </OutlineContainer>
      </InputFieldContainer>
    </Container>
  );
};

const MuiTextInput = ({ value = '', onChange, ...props }: Object) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event: Object) => {
    onChange(event);
    setInputValue(G.getEventTargetValue(event));
  };

  useEffect(() => {
    if (G.notEquals(value, inputValue)) setInputValue(value);
  }, [value, inputValue]);

  return (
    <Input
      {...props}
      value={inputValue}
      isFocused={isFocused}
      onChange={handleChange}
      setIsFocused={setIsFocused}
      handleFocus={() => setIsFocused(true)}
      handleBlur={() => setIsFocused(false)}
    />
  );
};

const MuiTextInputForDatePicker = ({ value, ...props }: Object) => (
  <Input {...props} value={value} onChange={() => {}} />
);

export {
  MuiTextInput,
  MuiTextInputForDatePicker,
};
