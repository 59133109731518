import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import TextComponent from '../text';
import { Switcher } from '../switcher';
import ZIndexClick from '../z-index-click';
import { DatePickerMui } from '../datepicker-mui';
// features
import { DatePicker as DatePicker2 } from '../../features/new-do/ui';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { DatePicker } from '../../forms/restyled/datepicker';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
// component date-range
import { CalendarWrapper } from './ui';
//////////////////////////////////////////////////

const getDateValue = (date: any) => {
  if (G.isValidDate(date)) return G.convertInstanceToDefaultDateFormat(date);

  return null;
};

const enhance = compose(
  withState('zI', 'setZIndex', 'unset'),
  withHandlers({
    handleSelectStartDate: ({ dateTo, onSelectDateRange }: Object) => (date: Object) => {
      onSelectDateRange({
        dateTo,
        dateFrom: getDateValue(date),
      });
    },
    handleSelectEndDate: ({ dateFrom, onSelectDateRange }: Object) => (date: Object) => {
      onSelectDateRange({
        dateFrom,
        dateTo: getDateValue(date),
      });
    },
  }),
  pure,
);

const DaysSelector = ({
  value,
  active,
  setQuickDays,
}: Object) => {
  const blueColor = G.getTheme('colors.dark.blue');
  const lightColor = G.getTheme('colors.light.mainLight');

  const textOptions = {
    ml: 15,
    fontSize: 11,
    p: '4px 10px',
    borderRadius: 3,
    fontWeight: 700,
    cursor: 'pointer',
    display: 'inline-block',
    bg: G.ifElse(active, blueColor, lightColor),
    color: G.ifElse(active, lightColor, blueColor),
  };

  return (
    <TextComponent
      {...textOptions}
      onClick={() => {
        if (active) return;

        setQuickDays(value);
      }}
    >
      {value} {G.getWindowLocale('titles:days', 'Days')}
    </TextComponent>
  );
};

const getMinDate = (props: Object) => {
  const { minDate } = props;

  if (G.isNotNilAndNotEmpty(minDate)) {
    return new Date(minDate);
  }

  return G.subtractDateTime(new Date(), 100, 'years');
};

const getMaxDate = (props: Object) => {
  const { maxDate } = props;

  if (G.isNotNilAndNotEmpty(maxDate)) {
    return new Date(maxDate);
  }

  return new Date();
};

const getShowDateRanges = ({ hideDateRanges }: Object) => R.not(G.isTrue(hideDateRanges));

const getCorrectDate = (date: any) => (
  G.isValidDate(date) ? new Date(date) : null
);

export const Component = (props: Object) => {
  const {
    zI,
    color,
    label,
    width,
    margin,
    dateTo,
    dateFrom,
    withIcon,
    quickDays,
    setZIndex,
    isClearable,
    borderColor,
    setQuickDays,
    labelFontSize,
    flexBoxStyles,
    quickDaysValue,
    withFilterIcon,
    handleSelectEndDate,
    handleSelectStartDate,
  } = props;

  const iconColor = G.getTheme('colors.dark.blue');

  const correctDateTo = G.isNotNilAndNotEmpty(dateTo) ? new Date(dateTo) : null;
  const correctDateFrom = G.isNotNilAndNotEmpty(dateFrom) ? new Date(dateFrom) : null;

  const commonProps = {
    zIndex: '0',
    isClearable,
    autoComplete: 'off',
    width: R.or(width, 100),
    onFocus: () => setZIndex(12),
    onBlur: () => setZIndex('unset'),
    onKeyDown: (e: Object) => e.preventDefault(),
  };

  return (
    <ZIndexClick>
      <Box m={R.or(margin, 12)}>
        <CalendarWrapper
          color={color}
          borderColor={borderColor}
        >
          <Flex zIndex={zI} {...R.or(flexBoxStyles, {})}>
            {
              withFilterIcon && <Box mr={10}>{I.filter(iconColor)}</Box>
            }
            {
              G.isNotNilAndNotEmpty(label) &&
              <Box mr='6px' fontSize={R.or(labelFontSize, 12)}>{label}:</Box>
            }
            <Flex>
              {
                getShowDateRanges(props) &&
                <DatePicker
                  {...commonProps}
                  selectsStart={true}
                  value={correctDateFrom}
                  maxDate={correctDateTo}
                  selected={correctDateFrom}
                  onChange={handleSelectStartDate}
                  placeholderText={G.getWindowLocale('titles:from', 'From')}
                />
              }
              {
                getShowDateRanges(props) &&
                <DatePicker
                  {...commonProps}
                  ml='8px'
                  selectsEnd={true}
                  value={correctDateTo}
                  selected={correctDateTo}
                  minDate={correctDateFrom}
                  onChange={handleSelectEndDate}
                  placeholderText={G.getWindowLocale('titles:to', 'To')}
                />
              }
              { withIcon && <Box ml={10} zIndex='0'>{I.calendar(iconColor, 16, 15)}</Box> }
              {
                G.isNotNilAndNotEmpty(quickDays) &&
                quickDays.map((value: number, i: number) => (
                  <DaysSelector
                    key={i}
                    value={value}
                    setQuickDays={setQuickDays}
                    active={R.equals(value, quickDaysValue)}
                  />
                ))
              }
            </Flex>
          </Flex>
        </CalendarWrapper>
      </Box>
    </ZIndexClick>
  );
};

export const Component2 = (props: Object) => {
  const {
    label,
    width,
    dateTo,
    dateFrom,
    withIcon,
    labelFontSize,
    initialZIndex,
    zIndexOnClick,
    popperPlacement,
    handleSelectEndDate,
    handleSelectStartDate,
  } = props;

  const iconColor = G.getTheme('colors.dark.blue');

  return (
    <ZIndexClick initialZIndex={initialZIndex} zIndexOnClick={zIndexOnClick}>
      <CalendarWrapper withoutArrow={true}>
        <Flex {...R.pathOr({}, ['flexBoxStyles'], props)}>
          {
            G.isNotNilAndNotEmpty(label) &&
            <Box mr={15} fontSize={R.or(labelFontSize, 12)} color={G.getTheme('colors.darkGrey')}>{label}</Box>
          }
          <Flex>
            <DatePicker2
              mr={15}
              autoComplete='off'
              placeholder='From'
              todayButton='Today'
              selectsStart={true}
              showYearDropdown={true}
              showMonthDropdown={true}
              width={R.or(width, 100)}
              maxDate={new Date(dateTo)}
              value={new Date(dateFrom)}
              minDate={getMinDate(props)}
              selected={new Date(dateFrom)}
              onChange={handleSelectStartDate}
              onKeyDown={(e: Object) => e.preventDefault()}
              popperPlacement={R.or(popperPlacement, 'bottom-start')}
            />
            { withIcon && <Box mr={15}>{I.calendar(iconColor, 16, 15)}</Box> }
            <DatePicker2
              placeholder='To'
              selectsEnd={true}
              autoComplete='off'
              todayButton='Today'
              selectsStart={true}
              showYearDropdown={true}
              value={new Date(dateTo)}
              showMonthDropdown={true}
              width={R.or(width, 100)}
              selected={new Date(dateTo)}
              minDate={new Date(dateFrom)}
              maxDate={getMaxDate(props)}
              onChange={handleSelectEndDate}
              onKeyDown={(e: Object) => e.preventDefault()}
              popperPlacement={R.or(popperPlacement, 'bottom-start')}
            />
          </Flex>
        </Flex>
      </CalendarWrapper>
    </ZIndexClick>
  );
};

const QuickDaysSwitcher = (props: Object) => {
  const {
    quickDays,
    setQuickDays,
    quickDaysValue,
  } = props;

  const switchIndexMap = R.compose(
    R.fromPairs,
    R.zipWith((days: number, index: number) => [days, index], quickDays),
  )(R.range(0, R.length(quickDays)));

  const switchOptions = R.map(
    (value: number) => ({
      value,
      width: 50,
      name: `${value} ${G.getWindowLocale('titles:days', 'days')}`,
    }),
    quickDays,
  );

  return (
    <Switcher
      ml={15}
      mr={15}
      version={3}
      fontSize={10}
      options={switchOptions}
      onSwitch={(value: string) => setQuickDays(value)}
      selectedOptionIndex={switchIndexMap[quickDaysValue]}
    />
  );
};

const Component3 = (props: Object) => {
  const {
    label,
    width,
    dateTo,
    labelTo,
    dateFrom,
    withIcon,
    labelFrom,
    iconColor,
    quickDays,
    isClearable,
    setQuickDays,
    inputSettings,
    labelFontSize,
    withFilterIcon,
    quickDaysValue,
    useNewMuiInputField,
    handleSelectEndDate,
    withoutBorder = false,
    handleSelectStartDate,
  } = props;

  const iconColorToUse = R.or(iconColor, G.getTheme('colors.dark.blue'));

  const inputSettingsToUse = R.or(
    inputSettings,
    {
      height: 24,
      fontSize: 11,
      borderRadius: 4,
      borderColor: G.getTheme('colors.dark.grey'),
    },
  );

  const correctDateTo = getCorrectDate(dateTo);
  const correctDateFrom = getCorrectDate(dateFrom);

  const commonProps = {
    width,
    isClearable,
    withoutBorder,
    useNewMuiInputField,
    inputReadOnly: true,
    withCalendarIcon: false,
    inputSettings: inputSettingsToUse,
  };

  return (
    <CalendarWrapper withoutArrow={true}>
      <Flex {...R.pathOr({}, ['flexBoxStyles'], props)}>
        {
          withFilterIcon && <Box mr={10}>{I.filter(iconColorToUse)}</Box>
        }
        {
          G.isNotNilAndNotEmpty(label) &&
          <Box mr={15} fontSize={R.or(labelFontSize, 12)} color={G.getTheme('colors.darkGrey')}>{label}</Box>
        }
        <Flex>
          <DatePickerMui
            {...commonProps}
            label={labelFrom}
            value={correctDateFrom}
            maxDate={correctDateTo}
            minDate={getMinDate(props)}
            onAccept={handleSelectStartDate}
            placeholder={G.getWindowLocale('titles:from', 'From')}
          />
          { withIcon ? <Box mx={10}>{I.calendar(iconColorToUse)}</Box> : <Box width={15} /> }
          <DatePickerMui
            {...commonProps}
            label={labelTo}
            value={correctDateTo}
            minDate={correctDateFrom}
            maxDate={getMaxDate(props)}
            onAccept={handleSelectEndDate}
            placeholder={G.getWindowLocale('titles:to', 'To')}
          />
        </Flex>
        {
          G.isNotNilAndNotEmpty(quickDays) &&
          <QuickDaysSwitcher
            quickDays={quickDays}
            setQuickDays={setQuickDays}
            quickDaysValue={quickDaysValue}
          />
        }
      </Flex>
    </CalendarWrapper>
  );
};

const DateSelector = enhance(Component);

const DateRange = enhance(Component2);

const DateRangeMui = enhance(Component3);

export default DateSelector;

export {
  DateRange,
  DateSelector,
  DateRangeMui,
};
