import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { GoogleMap, useJsApiLoader, TrafficLayer } from '@react-google-maps/api';
// common
import { makeSelectGoogleApiKey } from '../../common/selectors';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export { Directions } from './components/directions';
export { StopInfo, StopMarker } from './components/stop-components';
export { MarkerWithInfo, FleetMarkerWithInfo } from './components/marker-with-info';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const libraries = ['places', 'drawing', 'geometry'];

const Component = (props: Object) => {
  const {
    width,
    height,
    center,
    children,
    defaultZoom,
    trafficLayer,
    googleMapsApiKey,
  } = props;

  const { isLoaded } = useJsApiLoader({
    libraries,
    googleMapsApiKey,
    id: 'google-map-script',
  });


  if (G.isFalse(isLoaded)) return null;

  return (
    <GoogleMap
      zoom={R.or(defaultZoom, 4)}
      mapContainerStyle={{ ...containerStyle, width, height }}
      center={R.or(center, { lat: 38.755157, lng: -98.269035 })}
    >
      {R.or(children, null)}
      {trafficLayer && <TrafficLayer />}
    </GoogleMap>
  );
};

export const Map = (props: Object) => {
  const googleMapsApiKey = useSelector(makeSelectGoogleApiKey());

  if (R.isNil(googleMapsApiKey)) return null;

  return <Component {...props} googleMapsApiKey={googleMapsApiKey} />;
};
