import * as R from 'ramda';
import React from 'react';
import FileSaver from 'file-saver';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { LocalLoader } from '../local-loader';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box, AbsoluteBox } from '../../ui';
// component document-preview
import { FileViewerWrapper } from './ui';
import DocumentView from './document-view';
import { withAsyncFileOnDidMount } from './with-async-file';
//////////////////////////////////////////////////

const enhanceAsync = compose(
  withAsyncFileOnDidMount,
  withHandlers({
    handleDownload: (props: Object) => () => (
      FileSaver.saveAs(
        props.asyncInitialData.data,
        R.path(['asyncOptions', 'params', GC.FIELD_FILE_NAME], props),
      )
    ),
  }),
  pure,
);

export const DocumentPreview = (props: Object) => {
  const { filePath } = props;

  return (
    <Box width='100%' overflow='auto' position='static' height='calc(95vh - 30px)'>
      <FileViewerWrapper>
        <DocumentView filePath={filePath} fileType={R.pathOr('pdf', ['fileType'], props)} />
      </FileViewerWrapper>
    </Box>
  );
};

export const AsyncDocumentPreview = enhanceAsync((props: Object) => {
  const { asyncInitialData } = props;
  const { data, loading } = asyncInitialData;
  let url = null;

  if (G.isNotNilAndNotEmpty(data)) url = window.URL.createObjectURL(data);

  const darkBlue = G.getTheme('colors.dark.blue');

  return (
    <LocalLoader localLoaderOpen={R.and(loading, R.isNil(url))}>
      <Box width='100%' overflow='auto' position='static' height='calc(95vh - 30px)'>
        <FileViewerWrapper>
          <DocumentView filePath={url} fileType={R.pathOr(GC.EXTENSION_PDF, ['fileType'], props)} />
        </FileViewerWrapper>
        <AbsoluteBox
          p='8px'
          zIndex={11}
          right='20px'
          bottom='20px'
          color={darkBlue}
          cursor='pointer'
          border='1px solid'
          borderRadius='5px'
          borderColor={darkBlue}
          textTransform='uppercase'
          onClick={props.handleDownload}
        >
          {G.getWindowLocale('actions:download', 'Download')}
        </AbsoluteBox>
      </Box>
    </LocalLoader>
  );
});
