import * as R from 'ramda';
import L from 'leaflet';
import React from 'react';
import { css } from 'styled-components';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import { compose, withHandlers } from 'react-recompose';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Popup, Marker, TileLayer, MapContainer, FeatureGroup } from 'react-leaflet';
// helpers/constants
import * as G from '../../helpers';
// component drawing-map
import { PopupContent } from './components/popup-content';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleCreateComplete: (props: Object) => (data: Object) => {
      const { locations, handleCompleteSelection } = props;

      const layer = data.layer.getBounds();

      const results = R.filter(
        ({ latLng }: Object) => layer.contains(latLng),
        locations,
      );

      const callback = () => data.sourceTarget.removeLayer(data.layer);

      G.callFunctionWithArgs(handleCompleteSelection, { results, callback });
    },
  }),
);

const getMapCenterAndBounds = (locations: Array = []) => {
  if (G.isNilOrEmpty(locations)) return { center: [38.755157, -98.269035] };

  const bounds = R.map(({ latLng }: Object) => R.values(latLng), locations);
  const polygon = L.polygon(bounds);

  return {
    bounds,
    center: polygon.getBounds().getCenter(),
  };
};

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.light.blue');
const greyColor = G.getTheme('colors.light.darkGrey');

const getIcon = ({ selected, eventType }: Object) => new L.DivIcon({
  className: 'leaflet-marker',
  html: `
    <div class='leaflet-marker' style='width: 0; height: 0; cursor: pointer; overflow: visible;'>
      <div style='transform: translate(-20px, -150%);'>
        <div
          class='leaflet-marker-inner ${selected && 'selected'}'
          style='
            width: 36px;
            height: 36px;
            cursor: pointer;
            position: relative;
            border-radius: 50%;
            transform: scale(1.1);
            transition: 0.2s transform;
            background-color: ${whiteColor};
            transition: 0.2s transform, 0.2s -webkit-transform;
            border: 5px solid ${G.ifElse(selected, greyColor, blueColor)};
          '
        >
          <div
            style='
              width: 100%;
              height: 100%;
              overflow: hidden;
              border-radius: 50%;
            '
          >
            <div
              style='
                width: 100%;
                height: 100%;
                display: flex;
                font-weight: bold;
                align-items: center;
                justify-content: center;
                color: ${G.ifElse(selected, greyColor, blueColor)};
              '
            >
              ${R.head(eventType)}
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
});

const LeafletMap = enhance((props: Object) => {
  const {
    height,
    locations,
    handleCreateComplete,
    handleCompleteSelection,
  } = props;

  const { center, bounds } = getMapCenterAndBounds(locations);

  return (
    <MapContainer
      zoom={4}
      maxZoom={18}
      bounds={bounds}
      center={center}
      css={css`height: ${height};`}
    >
      <FeatureGroup>
        <EditControl
          position='topleft'
          onCreated={handleCreateComplete}
          edit={{
            edit: false,
            remove: false,
          }}
          draw={{
            marker: false,
            polyline: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {
        G.isNotNilAndNotEmpty(locations) &&
        <MarkerClusterGroup>
          {
            locations.map((location: Object, i: number) => (
              <Marker key={i} position={location.latLng} icon={getIcon(location)}>
                <Popup><PopupContent location={location} handleSelect={handleCompleteSelection} /></Popup>
              </Marker>
            ))
          }
        </MarkerClusterGroup>
      }
    </MapContainer>
  );
});

export default LeafletMap;
