import * as R from 'ramda';
import * as P from 'plow-js';
import { connect } from 'react-redux';
import { css } from 'styled-components';
import { Waypoint } from 'react-waypoint';
import React, { useRef, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import { TextComponent } from '../../text';
// features
import { AuthWrapper } from '../../../features/permission/index';
// hocs
import { LocalLoader } from '../../local-loader';
import { withBooleanStatus } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  IconWrapper,
  RelativeFlex,
  pulseOpacity,
  StickedWrapper,
} from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature table
import TitleSort from './title-sort';
import TitleFilter from './title-filter';
import { initialTableSettings } from '../settings';
import {
  makeSelectIDB,
  makeSelectIDBProps,
  makeSelectResizeTable,
} from '../../../common/idb/resize/selectors';
import {
  ActionCell,
  EnumElement,
  BranchElement,
  CurrencyField,
  ClickableLink,
  BooleanElement,
  UomRelatedField,
  FreightClassElement,
  SelectDropdownMuiCell,
  ActionCellWithExpandedContainer,
} from './elements';
import {
  TableRow,
  TitleRow,
  Checkbox,
  EmptyList,
  TableCell,
  TitleCell,
  TableInner,
  TableWrapper,
  WithBoxShadow,
  FlexContainer,
  CellWithBackground,
  TitleCellWithBackground,
} from '../ui';
import {
  setName,
  getWidth,
  getRowBgColor,
  getRelativeWidth,
  isColumnResizable,
  getIsSearchableField,
  filterTypesWithMuiTitleInput,
} from './helpers';
import {
  CustomElement,
  ExpandedDetails,
  CheckBoxComponent,
  StickedCellWithIcons,
  RightStickedComponent,
  AdditionalRowComponent,
} from './table-components';
//////////////////////////////////////////////////

const TitleCellComponent = ({
  name,
  type,
  width,
  sortable,
  maxWidth,
  resizable,
  reference,
  fieldName,
  searchable,
  fieldProps,
  resizeByGuid,
  tableSettings,
  resizeObserver,
  titleSortValue,
  clickableTitle,
  titleFilterValue,
  customTitleComponent,
  handleTableTitleFilter,
  useNewTitleFilterInputs,
  handleUpdateTableResize,
}: Object) => {
  const { searchableTitles, disableRefFilter, handleTitleCellClick } = tableSettings;

  const cellRef = useRef(null);

  let storedWidth;

  if (resizable) {
    const cellEl = cellRef.current;

    storedWidth = R.prop(fieldName, resizeByGuid);

    if (cellEl) {
      cellEl[GC.FIELD_NAME] = fieldName;
      resizeObserver?.observe(cellEl);
    }
  }

  const clickHandler = G.ifElse(
    R.and(clickableTitle, G.isFunction(handleTitleCellClick)),
    () => handleTitleCellClick(fieldName),
    () => {},
  );

  const resultWidth = R.or(storedWidth, width);
  const needCalcWidth = R.isNotNil(R.prop(GC.FIELD_OPTIONS, fieldProps));
  const relativeWidth = getRelativeWidth({ type, resizable, needCalcWidth });
  const isSearchableField = getIsSearchableField({ reference, searchable, disableRefFilter });

  if (R.and(isSearchableField, searchableTitles)) {
    return (
      <Flex
        zIndex={0}
        p='4px 8px'
        height='100%'
        ref={cellRef}
        width={resultWidth}
        flexDirection='column'
        onClick={clickHandler}
        alignItems='flex-start'
        style={{width: resultWidth}} //<-- necessary to reset resize
        minWidth={R.divide(width, 2)}
        maxWidth={R.multiply(width, 2)}
        overflow={G.ifElse(resizable, 'auto', 'inherit')}
        resize={G.ifElse(resizable, 'horizontal', 'none')}
        justifyContent={G.ifElse(useNewTitleFilterInputs, 'flex-end', 'normal')}
      >
        {
          G.isFunction(customTitleComponent) &&
          customTitleComponent(fieldName)
        }
        {
          R.or(
            R.and(
              G.isNilOrEmpty(useNewTitleFilterInputs),
              G.isNilOrEmpty(customTitleComponent),
            ),
            R.and(
              G.isNotNilAndNotEmpty(useNewTitleFilterInputs),
              G.notContain(fieldProps.type, filterTypesWithMuiTitleInput),
            ),
          ) &&
          <Flex
            maxWidth='100%'
            overflow='hidden'
            alignItems='center'
            height='-webkit-fill-available'
          >
            <TextComponent title={name} fontSize={11} withEllipsis={true} color={G.getTheme('colors.textDark')}>
              {name}
            </TextComponent>
          </Flex>
        }
        {
          G.isNotNilAndNotEmpty(fieldProps) &&
          <RelativeFlex
            width={relativeWidth}
            height='-webkit-fill-available'
          >
            <TitleFilter
              title={name}
              width='100%'
              fieldProps={fieldProps}
              titleFilterValue={titleFilterValue}
              maxWidth={R.subtract(resultWidth, 30)}
              fontSize={tableSettings.titleFontSize}
              handleTableTitleFilter={handleTableTitleFilter}
              useNewTitleFilterInputs={useNewTitleFilterInputs}
            />
            {
              sortable &&
              <TitleSort
                fieldProps={fieldProps}
                titleSortValue={titleSortValue}
                handleTableTitleFilter={handleTableTitleFilter}
              />
            }
          </RelativeFlex>
        }
      </Flex>
    );
  }

  return (
    <Flex
      p='8px'
      height='100%'
      ref={cellRef}
      width={resultWidth}
      onClick={clickHandler}
      style={{width: resultWidth}} //<-- necessary to reset resize
      minWidth={R.divide(width, 2)}
      maxWidth={R.multiply(width, 2)}
      overflow={G.ifElse(resizable, 'auto', 'inherit')}
      resize={G.ifElse(resizable, 'horizontal', 'none')}
    >
      {
        G.isFunction(customTitleComponent) &&
        customTitleComponent(fieldName)
      }
      {
        G.isNilOrEmpty(customTitleComponent) &&
        <Flex
          height='100%'
          overflow='hidden'
          alignItems='center'
        >
          <TextComponent title={name} withEllipsis={true}>
            {name}
          </TextComponent>
        </Flex>
      }
    </Flex>
  );
};

const renderTitleFields = (props: Object) => {
  const {
    fields,
    filterProps,
    resizeByGuid,
    tableSettings,
    columnSettings,
    resizeObserver,
    titleSortValues,
    tableResizeData,
    tableTitleFilters,
    useSearchableColumns,
    withResizableColumns,
    handleTableTitleFilter,
    useNewTitleFilterInputs,
    handleUpdateTableResize,
    setResizedFieldToLocalStorage,
  } = props;

  const {
    coloredHeader,
    useMainColors,
    moreBtnCellWidth,
    withoutFieldSort,
    additionalTitleBgColor,
  } = tableSettings;

  let left = R.path(['checkBoxCellWidth'], tableSettings);

  const fieldsLength = R.prop('length', fields);

  return fields.map((field: Object, i: number) => {
    const { right, freezed, reference, freezedRight, clickableTitle, customTitleComponent } = field;

    const fieldName = R.prop(GC.FIELD_NAME, field);

    const name = setName(field, props);

    let fieldProps = R.pathOr({}, [fieldName], filterProps);

    if (reference) {
      fieldProps = {
        type: 'reference',
        value: G.getPropFromObject(GC.FIELD_NAME, field),
      };
    }

    const resizable = isColumnResizable({
      fieldName,
      fieldProps,
      columnSettings,
      withResizableColumns,
    });

    const sortable = R.pathOr(R.not(withoutFieldSort), [fieldName, 'sortable'], columnSettings);

    const searchable = R.pathOr(G.isTrue(useSearchableColumns), [fieldName, 'searchable'], columnSettings);

    const width = getWidth({ fieldName, filterProps, columnSettings, useNewTitleFilterInputs });

    const maxWidth = R.path([fieldName, 'styles', 'titleInputMaxWidth'], columnSettings);

    const type = R.path([fieldName, 'type'], columnSettings);


    const titleFilterValue = R.pathOr({}, [fieldName], tableTitleFilters);
    const titleSortValue = R.pathOr({}, [fieldName], titleSortValues);

    let storedWidth;

    if (resizable) {
      storedWidth = R.prop(fieldName, resizeByGuid);
    }

    const newWidth = R.or(storedWidth, width);

    const cellLeft = left;

    left = R.add(left, R.or(newWidth, 0));

    if (R.or(freezed, freezedRight)) {
      const positionOption = G.ifElse(
        freezed,
        { left: cellLeft },
        { right: R.or(right, R.or(moreBtnCellWidth, '0px')) },
      );

      const zIndex = R.subtract(fieldsLength, i);

      return (
        <StickedWrapper
          bgColor='unset'
          zIndex={zIndex}
          width={newWidth}
          {...positionOption}
          key={`sticked-field-table-${i}`}
        >
          <TitleCellWithBackground
            colored={coloredHeader}
            mainColor={useMainColors}
            bgColor={additionalTitleBgColor}
            className='cell-with-background'
          >
            {
              R.not(R.path([field.name, 'withoutTitle'], columnSettings)) &&
              <TitleCellComponent
                name={name}
                type={type}
                width={width}
                sortable={sortable}
                maxWidth={maxWidth}
                resizable={resizable}
                reference={reference}
                fieldName={fieldName}
                searchable={searchable}
                fieldProps={fieldProps}
                key={`field-table-${i}`}
                resizeByGuid={resizeByGuid}
                tableSettings={tableSettings}
                resizeObserver={resizeObserver}
                titleSortValue={titleSortValue}
                clickableTitle={clickableTitle}
                tableResizeData={tableResizeData}
                titleFilterValue={titleFilterValue}
                customTitleComponent={customTitleComponent}
                handleTableTitleFilter={handleTableTitleFilter}
                useNewTitleFilterInputs={useNewTitleFilterInputs}
                handleUpdateTableResize={handleUpdateTableResize}
                setResizedFieldToLocalStorage={setResizedFieldToLocalStorage}
              />
            }
          </TitleCellWithBackground>
        </StickedWrapper>
      );
    }

    return (
      <TitleCellComponent
        name={name}
        type={type}
        width={width}
        sortable={sortable}
        maxWidth={maxWidth}
        resizable={resizable}
        reference={reference}
        fieldName={fieldName}
        searchable={searchable}
        fieldProps={fieldProps}
        key={`field-table-${i}`}
        resizeByGuid={resizeByGuid}
        tableSettings={tableSettings}
        resizeObserver={resizeObserver}
        titleSortValue={titleSortValue}
        clickableTitle={clickableTitle}
        tableResizeData={tableResizeData}
        titleFilterValue={titleFilterValue}
        customTitleComponent={customTitleComponent}
        handleTableTitleFilter={handleTableTitleFilter}
        useNewTitleFilterInputs={useNewTitleFilterInputs}
        handleUpdateTableResize={handleUpdateTableResize}
        setResizedFieldToLocalStorage={setResizedFieldToLocalStorage}
      />
    );
  });
};

export const TitleRowComponent = (props: Object) => {
  const {
    report,
    itemList,
    allChecked,
    filterProps,
    resizeByGuid,
    onOptionClick,
    tableSettings,
    columnSettings,
    resizeObserver,
    withoutTitleRow,
    titleSortValues,
    tableResizeData,
    lastFreezedIndex,
    tableTitleFilters,
    useSearchableColumns,
    withResizableColumns,
    handleClickResetIcon,
    handleTableTitleFilter,
    useNewTitleFilterInputs,
    handleUpdateTableResize,
    setResizedFieldToLocalStorage,
  } = props;

  if (G.isTrue(withoutTitleRow)) return null;

  const disabled = R.any((item: Object) => {
    const editable = R.prop('editable', item);

    return G.ifElse(G.isBoolean(editable), R.not(editable), false);
  }, R.or(itemList, []));

  return (
    <StickedWrapper
      top='0px'
      zIndex={2}
    >
      <TitleRow
        color={tableSettings.titleTextColor}
        height={tableSettings.titleRowHeight}
        colored={tableSettings.coloredHeader}
        fontSize={tableSettings.titleFontSize}
        mainColor={tableSettings.useMainColors}
        bgColor={tableSettings.additionalTitleBgColor}
      >
        <StickedWrapper
          left='0px'
          zIndex={1}
          bgColor='unset'
          width={tableSettings.checkBoxCellWidth}
          minWidth={tableSettings.checkBoxCellWidth}
        >
          <TitleCellWithBackground
            className='cell-with-background'
            colored={tableSettings.coloredHeader}
            mainColor={tableSettings.useMainColors}
            bgColor={tableSettings.additionalTitleBgColor}
          >
            {
              R.and(
                G.isTrue(tableSettings.allowSelectAll),
                G.isTrue(tableSettings.allowSelectItems),
              ) &&
              <CheckBoxComponent
                id='all'
                name='select all'
                disabled={disabled}
                checked={allChecked}
                ml={G.ifElse(tableSettings.expandableItems, 27, 'unset')}
                justifyContent={tableSettings.checkBoxCellJustifyContent}
                checkBoxCellBorderColor={tableSettings.checkBoxCellBorderColor}
                onOptionClick={
                  G.ifElse(
                    R.or(
                      disabled,
                      R.not(R.path(['length'], itemList))
                    ),
                    () => {},
                    onOptionClick,
                  )
                }
                mainColors={tableSettings.useMainColors}
              />
            }
          </TitleCellWithBackground>
        </StickedWrapper>
        {
          renderTitleFields({
            filterProps,
            resizeByGuid,
            tableSettings,
            columnSettings,
            resizeObserver,
            titleSortValues,
            tableResizeData,
            lastFreezedIndex,
            tableTitleFilters,
            useSearchableColumns,
            withResizableColumns,
            handleTableTitleFilter,
            useNewTitleFilterInputs,
            handleUpdateTableResize,
            setResizedFieldToLocalStorage,
            fields: R.sortBy(R.prop('sequence'), report.fields),
          })
        }
        {
          G.isTrue(tableSettings.allowEditBtn) &&
          <StickedWrapper
            ml='auto'
            right='0px'
            bgColor='unset'
            width={tableSettings.moreBtnCellWidth}
            minWidth={tableSettings.moreBtnCellWidth}
          >
            <TitleCellWithBackground
              className='cell-with-background'
              colored={tableSettings.coloredHeader}
              mainColor={tableSettings.useMainColors}
              bgColor={tableSettings.additionalTitleBgColor}
            >
              { G.isTrue(withResizableColumns) &&
                <FlexContainer
                  p='8px'
                  height='100%'
                  justify='center'
                  direction='column'
                  title='Reset table columns width'
                >
                  <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickResetIcon(e)}>
                    {I.threeDots()}
                  </IconWrapper>
                </FlexContainer>
              }
              { G.isFalse(withResizableColumns) &&
                <TitleCell p='8px' width={tableSettings.moreBtnCellWidth} minWidth={tableSettings.moreBtnCellWidth} />
              }
            </TitleCellWithBackground>
          </StickedWrapper>
        }
      </TitleRow>
    </StickedWrapper>
  );
};

const TableFieldComponent = (props: Object) => (
  <TableCell
    key={props.key}
    width={props.width}
    minWidth={props.width}
    p={R.pathOr('8px', ['field', 'p'], props)}
  >
    <FlexContainer height='100%' justify='center' direction='column'>
      <TextComponent
        display='block'
        overflow='hidden'
        title={props.value}
        withEllipsis={true}
        maxWidth={`calc(${props.width} - 8px)`}
      >
        {props.value}
      </TextComponent>
    </FlexContainer>
  </TableCell>
);

const UomRelatedFields = [
  GC.FIELD_WEIGHT,
  GC.FIELD_DISTANCE,
  GC.FIELD_ITEM_VOLUME,
  GC.FIELD_TEMPERATURE,
];

const TableField = (props: Object) => {
  const {
    data,
    field,
    width,
    uiType,
    settings,
    isCustom,
    rowIndex,
    resizable,
    fieldData,
    callbackData,
    resizeByGuid,
    cellPermissions,
    useExpandedContainer,
    openContainerAsNewWindow,
  } = props;

  let storedWidth;

  if (resizable) {
    const fieldName = R.prop(GC.FIELD_NAME, field);
    storedWidth = R.prop(fieldName, resizeByGuid);
  }

  const newWidth = R.or(storedWidth, width);

  if (isCustom) {
    return (
      <CustomElement
        data={data}
        field={field}
        width={newWidth}
        rowIndex={rowIndex}
        fieldData={fieldData}
        callbackData={callbackData}
        component={settings.customComponent}
      />
    );
  }

  if (R.and(R.includes(uiType, UomRelatedFields), G.isNotNilAndNotEmpty(fieldData))) {
    return (
      <UomRelatedField
        type={uiType}
        field={field}
        width={newWidth}
        fieldData={fieldData}
        withoutConversion={R.pathOr(false, ['withoutConversion'], settings)}
      />
    );
  }

  if (R.equals(uiType, GC.FIELD_CURRENCY)) {
    return (
      <CurrencyField data={data} value={fieldData} field={field} width={newWidth} callbackData={callbackData} />
    );
  }

  if (R.equals(uiType, 'boolean')) {
    return (
      <BooleanElement value={fieldData} data={data} width={newWidth} field={field} />
    );
  }

  if (R.equals(uiType, 'booleanReverse')) {
    return (
      <BooleanElement value={R.not(fieldData)} data={data} width={newWidth} field={field} />
    );
  }

  if (R.equals(uiType, 'enum')) {
    return (
      <EnumElement value={R.or(fieldData, '')} data={data} width={newWidth} field={field} />
    );
  }

  if (R.equals(uiType, 'freightClass')) {
    return (
      <FreightClassElement value={fieldData} data={data} width={newWidth} field={field} />
    );
  }

  if (R.equals(uiType, 'enterpriseGuid')) {
    return (
      <BranchElement value={R.or(fieldData, '')} data={data} width={newWidth} field={field} />
    );
  }

  if (R.equals(uiType, 'clickableLink')) {
    return (
      <ClickableLink
        data={data}
        field={field}
        width={newWidth}
        settings={settings}
        value={R.or(fieldData, '')}
      />
    );
  }

  if (R.equals(uiType, 'selectDropdown')) {
    return (
      <SelectDropdownMuiCell
        data={data}
        field={field}
        width={newWidth}
        settings={settings}
        value={R.or(fieldData, '')}
        callbackData={callbackData}
      />
    );
  }

  if (R.includes(uiType, [GC.PAGE_DISPATCH_DETAILS_NEW_LOAD, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER])) {
    const isClo = R.equals(uiType, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER);
    const guid = R.path([settings.guidPropName], data);
    const openLoadInSidebar = G.getAmousConfigByNameFromWindow(GC.UI_OPEN_LOAD_IN_SIDEBAR);

    if (R.and(G.isTrue(openLoadInSidebar), G.isTrue(useExpandedContainer))) {
      return (
        <ActionCellWithExpandedContainer
          field={field}
          text={fieldData}
          width={newWidth}
          visitPageGuid={guid}
          componentType={uiType}
        />
      );
    }

    const action = () => {
      const route = G.getLoadRouteByConfigAndLoadType(routesMap, guid, isClo);

      if (G.isTrue(openContainerAsNewWindow)) {
        return window.open(
          `${window.location.origin}${route}`,
          'Details',
          'width:200,height:200',
        );
      }

      return G.goToRoute(route);
    };

    return <ActionCell text={fieldData} action={action} width={newWidth} field={field} />;
  }

  if (R.and(G.isTrue(useExpandedContainer), G.hasAmousCurrentUserPermissions(cellPermissions))) {
    const rowGuid = G.getGuidFromObject(data);

    // TODO: check if it is good approach to use rowGuid as default here
    const visitPageGuid = R.pathOr(rowGuid, [settings.guidPropName], data);

    return (
      <ActionCellWithExpandedContainer
        field={field}
        text={fieldData}
        width={newWidth}
        rowGuid={rowGuid}
        componentType={uiType}
        visitPageGuid={visitPageGuid}
        cellPermissions={cellPermissions}
      />
    );
  }

  let valueToDisplay = fieldData;

  if (R.and(R.equals(uiType, 'enumLocale'), G.isNotNilAndNotEmpty(fieldData))) {
    valueToDisplay = G.getEnumLocale(fieldData);
  } else if (R.and(R.equals(uiType, 'uomLocale'), G.isNotNilAndNotEmpty(fieldData))) {
    valueToDisplay = G.getUomLocale(fieldData);
  } else if (R.equals(uiType, 'collectionOfEnums')) {
    valueToDisplay = R.compose(
      R.join(', '),
      R.map(R.compose(
        R.join(' '),
        R.map(G.toTitleCase),
        R.split('_'),
      )),
      R.uniq,
    )(R.or(fieldData, []));
  } else if (G.isArray(fieldData)) {
    valueToDisplay = G.createStringFromArray(fieldData, ', ');
  } else if (R.equals(uiType, 'date')) {
    const format = G.ifElse(R.gt(R.length(fieldData), 12), GC.FIELD_DATE_TIME, GC.FIELD_DATE);

    valueToDisplay = G.checkAndConvertMomentInstanceOrStringToFormattedDate(
      fieldData,
      G.getDateTimeFormatFromConfig(format),
    );
  } else if (R.equals(uiType, 'number')) {
    valueToDisplay = G.toFixed(fieldData);
  } else if (R.equals(uiType, 'location')) {
    const location = G.getPropFromObject(GC.SYSTEM_OBJECT_LOCATION, data);

    valueToDisplay = G.renderSmallAddressString(location);
  }

  return <TableFieldComponent field={field} width={newWidth} value={valueToDisplay} />;
};

const getFieldData = (fieldName: string, data: Object, getDataByPath: any) => {
  if (R.isNil(fieldName)) return null;

  if (R.and(
    G.isTrue(getDataByPath),
    R.gt(R.length(R.split('.', fieldName)), 1)
  )) return P.$get(fieldName, data);

  return G.getPropFromObject(fieldName, data);
};

const renderTableFields = (props: Object) => {
  const {
    data,
    fields,
    bgColor,
    rowIndex,
    filterProps,
    resizeByGuid,
    callbackData,
    tableSettings,
    columnSettings,
    tableResizeData,
    lastFreezedIndex,
    withResizableColumns,
    useNewTitleFilterInputs,
  } = props;

  let left = tableSettings.checkBoxCellWidth;

  return fields.map((field: Object, i: number) => {
    const fieldName = R.prop(GC.FIELD_NAME, field);
    const fieldProps = R.pathOr({}, [fieldName], filterProps);
    const settings = R.path([fieldName], columnSettings);
    const isCustom = G.isNotNilAndNotEmpty(R.path(['customComponent'], settings));
    const uiType = R.path(['type'], settings);
    const getDataByPath = R.path(['getDataByPath'], settings);
    const fieldData = getFieldData(fieldName, data, getDataByPath);
    const width = getWidth({ fieldName, filterProps, columnSettings, useNewTitleFilterInputs });

    const resizable = isColumnResizable({
      fieldName,
      fieldProps,
      columnSettings,
      withResizableColumns,
    });

    let storedWidth;
    if (resizable) {
      storedWidth = R.prop(fieldName, resizeByGuid);
    }

    const newWidth = R.or(storedWidth, width);

    const cellLeft = left;

    left = R.add(left, R.or(newWidth, 0));

    const useExpandedContainer = G.getPropFromObject('useExpandedContainer', settings);
    const openContainerAsNewWindow = G.getPropFromObject('openContainerAsNewWindow', settings);

    if (R.or(field.freezed, field.freezedRight)) {
      const positionOption = G.ifElse(
        field.freezed,
        { left: R.or(field.left, cellLeft) },
        { right: R.or(field.right, R.or(tableSettings.moreBtnCellWidth, '0px')) },
      );

      return (
        <StickedWrapper
          {...positionOption}
          zIndex={1}
          height='100%'
          bgColor='unset'
          width={newWidth}
          position={field.position}
          key={`sticked-field-table-${i}`}
        >
          <CellWithBackground
            bgColor={bgColor}
            highlighted={data.highlighted}
            className='cell-with-background'
            activeRowColor={tableSettings.activeRowColor}
            mainColors={G.isTrue(tableSettings.useMainColors)}
            active={R.and(G.isTrue(data.selected), G.isTrue(tableSettings.coloredActiveRow))}
          >
            <WithBoxShadow boxShadow={G.ifElse(R.equals(lastFreezedIndex, i), false, 'unset')}>
              <TableField
                data={data}
                field={field}
                width={width}
                uiType={uiType}
                rowIndex={rowIndex}
                settings={settings}
                isCustom={isCustom}
                resizable={resizable}
                fieldData={fieldData}
                key={`field-table-${i}`}
                resizeByGuid={resizeByGuid}
                callbackData={callbackData}
                tableResizeData={tableResizeData}
                useExpandedContainer={useExpandedContainer}
                cellPermissions={G.getPropFromObject('cellPermissions', settings)}
              />
            </WithBoxShadow>
          </CellWithBackground>
        </StickedWrapper>
      );
    }

    return (
      <TableField
        data={data}
        field={field}
        width={width}
        uiType={uiType}
        rowIndex={rowIndex}
        settings={settings}
        isCustom={isCustom}
        fieldData={fieldData}
        resizable={resizable}
        key={`field-table-${i}`}
        resizeByGuid={resizeByGuid}
        callbackData={callbackData}
        tableResizeData={tableResizeData}
        useExpandedContainer={useExpandedContainer}
        openContainerAsNewWindow={openContainerAsNewWindow}
        cellPermissions={G.getPropFromObject('cellPermissions', settings)}
      />
    );
  });
};

const TableRowComponent = withBooleanStatus(false)((props: Object) => {
  const {
    item,
    report,
    zIndex,
    toggle,
    rowIndex,
    rowStyles,
    iconColor,
    filterProps,
    callbackData,
    resizeByGuid,
    tableSettings,
    onOptionClick,
    rowBgFunction,
    actionButtons,
    columnSettings,
    mainLightColor,
    tableResizeData,
    setTableRowHover,
    lastFreezedIndex,
    unsetTableRowHover,
    withResizableColumns,
    handleShowListIssues,
    useNewTitleFilterInputs,
    renderRightStickedComponent,
  } = props;

  if (tableSettings.withOnlyExpandedDetails) return null;

  const textDecoration = G.ifElse(
    G.isTrue(item.cancelled),
    'line-through',
    'none',
  );

  const maxIssueWarningLevelValue = G.getPropFromObject(GC.FIELD_MAX_ISSUE_WARNING_LEVEL, item);
  const issues = G.getPropFromObject(GC.FIELD_ISSUES, item);
  const hasIssues = G.isNotNilAndNotEmpty(issues);

  let bgColor = getRowBgColor(issues, maxIssueWarningLevelValue);

  if (G.isFunction(rowBgFunction)) {
    bgColor = rowBgFunction(item[GC.FIELD_GUID], item);
  }

  return (
    <TableRow
      {...rowStyles}
      bgColor={bgColor}
      highlighted={item.highlighted}
      key={`table-row-${item.guid}`}
      textDecoration={textDecoration}
      fontSize={tableSettings.cellFontSize}
      height={tableSettings.tableRowHeight}
      pl={tableSettings.absoluteColumnWidth}
      activeRowColor={tableSettings.activeRowColor}
      mainColors={G.isTrue(tableSettings.useMainColors)}
      onMouseOver={() => G.callFunctionWithArgs(setTableRowHover, item)}
      onMouseLeave={() => G.callFunctionWithArgs(unsetTableRowHover, item)}
      active={R.and(G.isTrue(item.selected), G.isTrue(tableSettings.coloredActiveRow))}
    >
      <StickedCellWithIcons
        item={item}
        issues={issues}
        toggle={toggle}
        bgColor={bgColor}
        iconColor={iconColor}
        hasIssues={hasIssues}
        onOptionClick={onOptionClick}
        tableSettings={tableSettings}
        actionButtons={actionButtons}
        mainLightColor={mainLightColor}
        lastFreezedIndex={lastFreezedIndex}
        handleShowListIssues={handleShowListIssues}
      />
      {
        renderTableFields({
          bgColor,
          rowIndex,
          data: item,
          filterProps,
          callbackData,
          resizeByGuid,
          tableSettings,
          columnSettings,
          tableResizeData,
          lastFreezedIndex,
          withResizableColumns,
          fields: report.fields,
          useNewTitleFilterInputs,
        })
      }
      {
        G.isTrue(tableSettings.allowEditBtn) &&
        <StickedWrapper
          ml='auto'
          right='0px'
          height='100%'
          width={tableSettings.moreBtnCellWidth}
        >
          <CellWithBackground
            bgColor={bgColor}
            className='cell-with-background'
            activeRowColor={tableSettings.activeRowColor}
            mainColors={G.isTrue(tableSettings.useMainColors)}
            active={R.and(G.isTrue(item.selected), G.isTrue(tableSettings.coloredActiveRow))}
          >
            <FlexContainer p='8px' height='100%' justify='center' direction='column'>
              <RightStickedComponent
                item={item}
                rowIndex={rowIndex}
                renderRightStickedComponent={
                  (item: Object) => (
                    G.isFunction(renderRightStickedComponent)
                    && renderRightStickedComponent(item, props.setBooleanStatus)
                  )
                }
              />
            </FlexContainer>
          </CellWithBackground>
        </StickedWrapper>
      }
    </TableRow>
  );
});

const renderList = (props: Object) => {
  const count = props.itemList.length;
  const iconColor = G.getTheme('icons.iconColor');
  const mainLightColor = G.getTheme('colors.light.mainLight');

  return props.itemList.map((item: Object, i: number) => (
    [
      <TableRowComponent
        {...props}
        item={item}
        rowIndex={i}
        iconColor={iconColor}
        mainLightColor={mainLightColor}
        key={`table-list-row-${i}${item.guid}`}
        handleShowListIssues={props.handleShowListIssues}
      />,
      <AdditionalRowComponent
        item={item}
        AdditionalRow={props.AdditionalRow}
        key={`table-additional-row-${i}${item.guid}`}
      />,
      <ExpandedDetails
        item={item}
        callbackData={props.callbackData}
        key={`expanded-${i}${item.guid}`}
        tableSettings={props.tableSettings}
      />,
    ]
  ));
};

export const TableDimmer = (props: Object) => (
  <Box
    minWidth='100vw'
    css={`
      animation: ${pulseOpacity} 2s infinite linear
    `}
  >
    {
      props.withHeader &&
      <Box
        bg='#AFAFAF'
        width='100vw'
        borderRadius='10px'
        height={props.tableSettings.titleRowHeight}
      />
    }
    {
      G.getComponentCountTimes(
        props.count,
        (_: undefined, i: number) => (
          <Box
            key={i}
            mt='2px'
            bg='#d8d8d8'
            width='100vw'
            borderRadius='5px'
            height={R.subtract(props.tableSettings.tableRowHeight, 2)}
          />
        )
        ,
      )
    }
  </Box>
);

export const TableBody = (props: Object) => {
  const {
    data,
    loading,
    withWaypoint,
    resizeByGuid,
    resizeObserver,
    handleUpdateTableResize,
  } = props;

  const dataWithResize = R.mergeRight(data, {
    resizeByGuid,
    resizeObserver,
    handleUpdateTableResize,
  });

  if (R.and(G.isNilOrEmpty(data.itemList), R.not(loading))) {
    return (
      <TableInner minWidth={data.tableSettings.minWidth}>
        {
          data.tableSettings.withTitleRow &&
          <TitleRowComponent {...dataWithResize} />
        }
        {
          R.not(data.tableSettings.withoutEmptyList) &&
          <EmptyList
            p={data.tableSettings.emptyListPadding}
            textAlign={data.tableSettings.emptyListTextAlign}
          >
            {G.getWindowLocale('titles:empty-list', 'Empty List')}
          </EmptyList>
        }
      </TableInner>
    );
  }

  return (
    <TableInner minWidth={data.tableSettings.minWidth}>
      {
        data.tableSettings.withTitleRow &&
        <TitleRowComponent {...dataWithResize} />
      }
      {G.isNotNil(R.path(['subTitle'], data)) && data.subTitle}
      {renderList(dataWithResize)}
      {
        R.and(G.isTrue(withWaypoint), R.not(loading)) &&
        <Waypoint onEnter={data.handleLoadMoreEntities} />
      }
      {
        loading &&
        <LocalLoader minWidth={340} localLoaderOpen={true} />
      }
    </TableInner>
  );
};

