import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// component filter
import { reportConditions } from './settings';
//////////////////////////////////////////////////

export const setConditionName = (field: Object, params: Object) => {
  const dataType = R.find(
    R.propEq(R.or(params.operation, params.timeUnit), 'value'),
    reportConditions()[R.or(field.type, field.dataType)],
  );
  return R.pathOr('', ['name'], dataType);
};

export const setDateExpression = (field: Object, filteredParams: Object) => {
  if (G.isFalse(filteredParams.dateRelative)) {
    return R.mapObjIndexed((value: string, param: string) => {
      let expression = '';

      if (filteredParams[param]) {
        const date = G.convertInstanceToDefaultDateTimeFormat(filteredParams[param]);
        const localeValue = G.getWindowLocale(`titles:${param}`, param);

        expression = `${localeValue}${date}`;
      }

      return expression;
    }, { to: '', from: '' });
  }
  return R.mapObjIndexed((value: string, param: string) => {
    let expression = '';
    if (filteredParams[param]) {
      const localeValue = G.getWindowLocale(`titles:${param}`, param);
      expression = `${localeValue}${filteredParams[param]}`;
    }
    return expression;
  }, { last: '', next: '' });
};

export const compileExpression = (props: Object) => {
  const {
    filterBy,
    filteredParams,
    columnSettings } = props;
  const selectName = G.ifElse(
    R.equals(filteredParams.dataType, GC.FIELD_REFERENCE),
    G.getOrElse(filteredParams, GC.FIELD_REFERENCE_NAME, ''),
    G.getOrElse(filteredParams, 'propertyName', ''),
  );
  let filterField = R.find(R.propEq(selectName, 'value'), filterBy);
  filterField = G.ifElse(
    R.isNil(filterField),
    filteredParams,
    filterField,
  );
  const conditionName = setConditionName(filterField, filteredParams);
  let value = filteredParams[`${R.or(filterField.type, filterField.dataType)}Value`];
  if (R.equals(filterField.type, 'date')) {
    const expression = setDateExpression(filterField, filteredParams);
    value = G.ifElse(
      G.isFalse(filteredParams.dateRelative),
      `${expression.from} ${expression.to}`,
      `${expression.last} ${expression.next}`,
    );
  }
  let displayValue = value;
  if (G.isArray(value)) displayValue = R.map(R.prop('label'), value);
  const name = G.getWindowLocale(R.pathOr('', [selectName, 'name'], columnSettings), null);
  return G.ifElse(
    G.isNotNilAndNotEmpty(name),
    `${name} ${conditionName} ${displayValue}`,
    `${R.or(filterField.name, filterField.propertyName)} ${conditionName} ${displayValue}`,
  );
};
