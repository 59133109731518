import * as R from 'ramda';
import React from 'react';
import MaskedInput from 'react-text-mask';
import RDatePicker from 'react-datepicker';
// components
import ZIndexClick from '../z-index-click';
// features
import {
  getMask,
  getPipe,
  getSelected,
  getCorrectDateTimeString,
  getDateTimeFormatForValue,
  getDateTimeFormatForRender,
} from '../../features/new-do/forms/formik/calendar-time-input';
import { Input, Error, LabelBox } from '../../features/new-do/ui';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, RelativeBox } from '../../ui';
// component simple-date
import { CalendarWrapper } from './ui';
//////////////////////////////////////////////////

const MaskedDateInput = (props: Object) => {
  const {
    value,
    label,
    error,
    format,
    onBlur,
    errorTop,
    hasError,
    isRequired,
    width = 120,
    timeSelection,
    inputWrapperStyles,
  } = props;

  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [format],
    GC.dateTimeFormatMap,
  );
  const propName = G.ifElse(G.isTrue(timeSelection), 'dateTime', 'date');
  const placeholder = R.path([propName, 'placeholder'], formatDataObject);

  return (
    <MaskedInput
      value={value}
      onBlur={onBlur}
      pipe={getPipe(timeSelection, format)}
      mask={getMask(timeSelection, format)}
      render={(ref: Object, renderProps: Object) => (
        <RelativeBox {...G.spreadUiStyles(inputWrapperStyles)}>
          <LabelBox
            top={-13}
            left='5px'
            fontSize={11}
            color={G.getTheme('colors.darkGrey')}
            className={G.ifElse(isRequired, 'required', 'not-required')}
          >
            {label}
          </LabelBox>
          <Input ref={ref} width={width} placeholder={placeholder} {...renderProps} />
          {hasError && <Error title={error} width={width} top={errorTop}>{error}</Error>}
        </RelativeBox>
      )}
    />
  );
};

const CustomInput = ({ onClick }: Object) => (
  <Box
    ml='-35px'
    cursor='pointer'
    onClick={onClick}
  >
    {I.calendar(G.getTheme('colors.dark.blue'), 16, 15)}
  </Box>
);

export const SimpleDate = (props: Object) => {
  const {
    value,
    minDate,
    maxDate,
    onSelectDate,
    timeSelection,
    timeIntervals,
    zIndexOnClick,
    initialZIndex,
    formatUsOrUs24,
  } = props;

  const selected = getSelected(value);

  const dateTimeFormatUsOrUs24 = R.or(formatUsOrUs24, GC.DATE_TIME_FORMAT_US);

  const valueDateTimeFormat = getDateTimeFormatForValue(timeSelection);

  const handlePickerChange = (value: Object) => {
    const valueToSet = G.createLocalDateTimeFromInstanceOrISOString(value, valueDateTimeFormat);

    onSelectDate(valueToSet);
  };

  const handleInputBlur = (event: Object) => {
    const value = G.getEventTargetValue(event);
    const valid = G.isValidDateWithFormat(value, valueDateTimeFormat);

    if (G.isNilOrEmpty(value)) return onSelectDate(null);

    if (R.not(valid)) return;

    const valueToSet = G.createLocalDateTimeFromInstanceOrISOString(value, valueDateTimeFormat);

    onSelectDate(valueToSet);
  };

  const getCorrectValue = () => {
    if (G.isTrue(timeSelection)) return getCorrectDateTimeString(value, dateTimeFormatUsOrUs24);

    if (R.not(G.isValidDate(value))) return value;

    return G.createLocalDateTimeFromInstanceOrISOString(
      value,
      R.path([dateTimeFormatUsOrUs24, 'date', 'format'], GC.dateTimeFormatMap),
    );
  };

  const setMaxDate = () => {
    if (G.isNotNilAndNotEmpty(maxDate)) return maxDate;

    return G.addDateTime(new Date(), 100, 'years');
  };

  const setMinDate = () => {
    if (G.isNotNilAndNotEmpty(minDate)) return minDate;

    return G.subtractDateTime(new Date(), 100, 'years');
  };

  const validValue = getCorrectValue();

  const { timeFormat, dateTimeFormat } = getDateTimeFormatForRender(timeSelection, dateTimeFormatUsOrUs24);

  return (
    <Flex>
      <MaskedDateInput {...props} value={validValue} format={dateTimeFormatUsOrUs24} onBlur={handleInputBlur} />
      <ZIndexClick initialZIndex={initialZIndex} zIndexOnClick={zIndexOnClick}>
        <CalendarWrapper>
          <RDatePicker
            autoComplete='off'
            todayButton='Today'
            selected={selected}
            maxDate={setMaxDate()}
            minDate={setMinDate()}
            timeFormat={timeFormat}
            showYearDropdown={true}
            showMonthDropdown={true}
            dateFormat={dateTimeFormat}
            onChange={handlePickerChange}
            customInput={<CustomInput />}
            timeIntervals={R.or(timeIntervals, 15)}
            showTimeSelect={R.or(timeSelection, false)}
          />
        </CalendarWrapper>
      </ZIndexClick>
    </Flex>
  );
};
